<template>
       <div class="md:max-w-2xl mx-auto p-4">
        <form action="" @submit.prevent="formDataSubmit">
            <div class="w-full bg-white flex flex-col gap-4 p-4">
                <div class="mb-4 bg-green-400 rounded-md">
                     <h2 class="text-2xl text-white border p-2">Legally Binding Contract </h2>
                </div>
                <div class="w-full flex gap-4 justify-center items-center">
                    <label for="">Titel</label>
                    <input type="text" class="w-full py-2 border focus:outline-none rounded-md p-2" v-model="form.key" placeholder="titel">
                </div>
                <div class="w-full flex gap-4 justify-center">
                    <label for="" class="top-0">Content</label>
                    <textarea type="text" class="w-full py-2 p-2 border focus:outline-none rounded-md" rows="10" v-model="form.value" placeholder="Content">

                    </textarea>
                </div>

                <div>
                    <button type="submit" class="bg-blue-500 text-white p-2 rounded-md">Submit</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Input from '../../../components/input-new.vue'
import Textarea from '../../../components/input-text-area.vue'
export default {
    name:'create',
    components:{
        Input,
        Textarea
    },
    data(){
        return {
            form:{
                key:'',
                value:''
            }
        }
    },
    created(){
        this.$store.dispatch('legally_binding_contract/DataEdit',{
            id: this.$route.params.id
        });
    },
    watch: {    
        '$store.state.legally_binding_contract.edit'( data ){
            if( data ) {
               this.form.key =  data.key;
               this.form.value = data.value;   
            }
        }
    },
    computed:{
        list(){
            return this.$store.state.legally_binding_contract.edit
        }
    },
    methods: {
         async formDataSubmit(){
          await this.$store.dispatch('legally_binding_contract/Update',{
                id: this.$route.params.id,
                data: this.form,
           }).then( () => {
                this.$toast.success(`Record successfully Updated !!!`,{
                   position: "top-right",
                   duration:3000
                });
                this.$router.push('/admin/legally-binding-contract');
           });
        }
    }

}
</script>